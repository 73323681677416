import { defineMessages } from 'react-intl'

export default defineMessages({
  SelectAllSwimlanes: {
    id: 'app.components.ScopeAssignmentForm.SelectAllSwimlanes',
    defaultMessage: 'All Swimlanes',
  },
  SelectAllSwimlaneMessage: {
    id: 'app.components.ScopeAssignmentForm.SelectAllSwimlaneMessage',
    defaultMessage:
      'Assign this role for all swimlanes, including swimlanes created in the future',
  },
  ScopeLabel: {
    id: 'app.components.ScopeAssignmentForm.ScopeLabel',
    defaultMessage: 'Swimlane(s)',
  },
})
