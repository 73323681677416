import { defineMessages } from 'react-intl'

export default defineMessages({
  UserTitle: {
    id: 'app.containers.UserManagement.userTitle',
    defaultMessage: 'All Users',
  },
  PendingTitle: {
    id: 'app.containers.UserManagement.pendingTitle',
    defaultMessage: 'Pending User Invitations',
  },
  EditAssignments: {
    id: 'app.containers.UserManagement.editAssignments',
    defaultMessage: 'Edit Role Assignments',
  },
  CancelInvite: {
    id: 'app.containers.UserManagement.cancelInvite',
    defaultMessage: 'Cancel',
  },
  CancellingInvite: {
    id: 'app.containers.UserManagement.cancellingInvite',
    defaultMessage: 'Canceling...',
  },
  ResendInvite: {
    id: 'app.containers.UserManagement.resendInvite',
    defaultMessage: 'Resend Invitation',
  },
  ResendingInvite: {
    id: 'app.containers.UserManagement.resendingInvite',
    defaultMessage: 'Resending...',
  },
  InviteUser: {
    id: 'app.containers.UserManagement.invitedUser',
    defaultMessage: 'Invite User',
  },
  AddRoleAssignmentHeader: {
    id: 'app.containers.UserManagement.AddRoleAssignmentHeader',
    defaultMessage: 'Add Role Assignment',
  },
  RoleLable: {
    id: 'app.containers.UserManagement.RoleLable',
    defaultMessage: 'Role',
  },
  HidePermissionsLabel: {
    id: 'app.containers.UserManagement.ViewPermissionsLabel',
    defaultMessage: 'hide permissions',
  },
  ViewPermissionsLabel: {
    id: 'app.containers.UserManagement.ViewPermissionsLabel',
    defaultMessage: 'view permissions',
  },
  AllowPlatformPermissionsLabel: {
    id: 'app.containers.UserManagement.AllowPlatformPermissionsLabel',
    defaultMessage: 'Allow Platform Permissions',
  },
  SelectAllSwimlanes: {
    id: 'app.containers.UserManagement.SelectAllSwimlanes',
    defaultMessage: 'All Swimlanes',
  },
  SelectAllSwimlaneMessage: {
    id: 'app.containers.UserManagement.SelectAllSwimlaneMessage',
    defaultMessage:
      'Assign this role for all swimlanes, including swimlanes created in the future',
  },
  ScopeLabel: {
    id: 'app.containers.UserManagement.ScopeLabel',
    defaultMessage: 'Swimlane(s)',
  },
  userActivityLink: {
    id: 'app.containers.UserManagement.userActivityLink',
    defaultMessage: 'User Activity Logs',
  },
})
