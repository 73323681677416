import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { FormattedMessage } from 'react-intl'
import { FormGroup } from 'reactstrap'

import FormLabel from 'components/FormLabel'
import Select from 'components/Select'
import { PlainCheckbox } from 'components/Checkbox'
import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'

import messages from './messages'

export function ScopeAssignmentForm({
  swimlanes,
  scopeInputValue,
  platformPerm,
  handleScopeInputChange,
  handlePlatformPermClick,
  canSelectAllSwimlanes,
  isDisabled,
}) {
  const onChangeCheckbox = newVal => {
    handleScopeInputChange([])
    handlePlatformPermClick(newVal)
  }

  const scopeOptions = swimlanes
    .toList()
    .sortBy(swimlane => swimlane.get('title'))
    .toJS()
    .map(({ resourceId: value, title: label }) => ({ value, label }))

  return (
    <div>
      <FormGroup style={{ marginBottom: '0.5rem' }}>
        <FormLabel required style={{ margin: '0rem' }}>
          <FormattedMessage {...messages.ScopeLabel} />
        </FormLabel>
        <div style={{ margin: '0.25rem 0rem 0rem 0rem' }}>
          <Select
            menuPlacement="auto"
            isClearable
            isMulti
            isDisabled={platformPerm || isDisabled}
            options={scopeOptions}
            value={
              platformPerm ? { label: 'All', value: 'All' } : scopeInputValue
            }
            onChange={handleScopeInputChange}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div />
        {canSelectAllSwimlanes && (
          <div>
            <PlainCheckbox checked={platformPerm} onChange={onChangeCheckbox} />{' '}
            <FormattedMessage {...messages.SelectAllSwimlanes} />
            &nbsp;{' '}
            <Tooltip
              anchor={
                <Icon style={{ fontSize: '1.2rem' }} fa name="info-circle" />
              }
              tooltipContent={
                <FormattedMessage {...messages.SelectAllSwimlaneMessage} />
              }
            />
          </div>
        )}
      </FormGroup>
    </div>
  )
}

ScopeAssignmentForm.propTypes = {
  swimlanes: ImmutablePropTypes.map.isRequired,
  scopeInputValue: PropTypes.array,
  platformPerm: PropTypes.bool,
  handleScopeInputChange: PropTypes.func,
  handlePlatformPermClick: PropTypes.func,
  canSelectAllSwimlanes: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

export default ScopeAssignmentForm
