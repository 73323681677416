import { createSelector } from 'reselect'
import { Map, List, fromJS } from 'immutable'

const selectUserManagemenDomain = state =>
  state !== undefined ? state.get('userManagementData', Map()) : fromJS({})

export const selectUsers = createSelector(
  selectUserManagemenDomain,
  data => data.get('users') || List()
)

export const selectPendingUsers = createSelector(
  selectUserManagemenDomain,
  data => data.get('pendingUsers') || List()
)

export const selectFetchingUsers = createSelector(
  selectUserManagemenDomain,
  data => data.get('fetchingUsers')
)

export const selectFetchingPendingUsers = createSelector(
  selectUserManagemenDomain,
  data => data.get('fetchingPendingUsers')
)

export const selectInvitingUser = createSelector(
  selectUserManagemenDomain,
  data => data.get('invitingUser')
)

export const selectSonraiScopes = createSelector(
  selectUserManagemenDomain,
  data => data.get('sonraiScopes') || List()
)

export const selectSonraiRoles = createSelector(
  selectUserManagemenDomain,
  data => data.get('sonraiRoles') || List()
)

export const selectFetchingSonraiRoles = createSelector(
  selectUserManagemenDomain,
  data => data.get('fetchingSonraiRoles')
)

export const selectFetchingSonraiScopes = createSelector(
  selectUserManagemenDomain,
  data => data.get('fetchingSonraiScopes')
)

export const selectCancellingUserInvite = createSelector(
  selectUserManagemenDomain,
  data => data.get('cancellingUserInvite')
)

export const selectUpdatingUserInvite = createSelector(
  selectUserManagemenDomain,
  data => data.get('updatingUserInvite') || Map()
)

export const selectResendingUserInvite = createSelector(
  selectUserManagemenDomain,
  data => data.get('resendingUserInvite')
)

export const selectUserManagementError = createSelector(
  selectUserManagemenDomain,
  state => state.get('userManagementError')
)
