import { takeLatest, put } from 'redux-saga/effects'

import {
  FETCH_PENDING_USERS,
  FETCH_SONRAI_ROLES,
  CANCEL_USER_INVITE,
  INVITE_USER,
  UPDATE_USER_INVITE,
  RESEND_USER_INVITE,
  UPDATE_PENDING_ROLE_ASSIGNMENTS,
} from './constants'
import {
  fetchPendingUsersSuccess,
  fetchSonraiRolesSuccess,
  cancelUserInviteSuccess,
  inviteUserSuccess,
  updateUserInviteSuccess,
  resendUserInviteSuccess,
  updatePendingRoleAssignmentsSuccess,
  setUserManagementError,
} from './actions'
import { getClient } from 'apolloClient'
import gql from 'graphql-tag'
import _ from 'lodash'

const CANCEL_SONRAI_INVITE_QUERY = `
  mutation deleteSonraiInvite($input: [SonraiInviteDeleter!]!) {
    DeleteSonraiInvites(input: $input) {
      items {
        srn
        deleted
      }
    }
  }
`

const INVITE_USER_MUTATION = `
  mutation inviteUser($input: [SonraiInviteCreator!]!) {
    CreateSonraiInvites(input: $input) {
      items {
        srn
        resourceId
        email
        dateSent
        expiry
        isPending
        pendingRoleAssignments {
          items {
            srn
            role {
              items {
                srn
                name
              }
            }
            scope
          }
        }
      }
    }
  }
`

function* fetchPendingUsers() {
  const GET_SONRAI_INVITES_QUERY = `
    query getInvites {
      SonraiInvites {
        items {
          srn
          email
          dateSent
          isPending
          expiry
          pendingRoleAssignments {
            items {
              srn
              role {
                items {
                  srn
                  name
                }
              }
              scope
            }
          }
        }
      }
    }
  `

  try {
    const client = getClient()
    const data = yield client.query({
      query: gql`
        ${GET_SONRAI_INVITES_QUERY}
      `,
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })

    yield put(
      fetchPendingUsersSuccess({
        users: _.get(data, ['data', 'SonraiInvites', 'items'], []),
      })
    )
  } catch (e) {
    yield put(setUserManagementError(e.message))
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

function* fetchSonraiRoles() {
  const FETCH_SONRAI_ROLES_QUERY = `
    query getRoles {
      SonraiRoles {
        items {
          srn
          resourceId
          name
          description
          permissions
        }
      }
    }
  `
  try {
    const client = getClient()
    const data = yield client.query({
      query: gql`
        ${FETCH_SONRAI_ROLES_QUERY}
      `,
      forceFetch: true,
      fetchPolicy: 'no-cache',
    })

    yield put(
      fetchSonraiRolesSuccess({
        roles: _.get(data, ['data', 'SonraiRoles', 'items'], []),
      })
    )
  } catch (e) {
    yield put(setUserManagementError(e.message))
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

function* inviteUser(action) {
  try {
    const client = getClient()
    const data = yield client.mutate({
      mutation: gql`
        ${INVITE_USER_MUTATION}
      `,
      variables: {
        input: {
          email: action.payload.email,
          name: action.payload.name,
          pendingRoleAssigners: action.payload.roleAssignments.map(assign => {
            return { roleSrn: assign.role, scope: assign.scope }
          }),
        },
      },
    })

    const invitedUser = _.get(
      data,
      ['data', 'CreateSonraiInvites', 'items', 0],
      null
    )
    if (invitedUser) {
      yield put(inviteUserSuccess({ user: invitedUser }))
    }
  } catch (e) {
    yield put(setUserManagementError(e.message))
    yield put(inviteUserSuccess(null))
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

function* updatePendingRoleAssignments(action) {
  const CREATE_PENDING_ROLE_ASSIGNMENT = `
    mutation createPendingRoleAssignment($assignment: [SonraiPendingRoleAssignmentCreator!]!) {
      CreateSonraiPendingRoleAssignments(input: $assignment) {
        items {
          srn
          role {
            items {
              srn
              name
            }
          }
          scope
        }
      }
    }
  `

  const DELETE_PENDING_ROLE_ASSIGNMENT = `
    mutation deletePendingRoleAssignment($input: [SonraiPendingRoleAssignmentDeleter!]!) {
      DeleteSonraiPendingRoleAssignments(input: $input) {
        items {
          srn
          deleted
        }
      }
    }
  `

  try {
    const added = []
    const removed = []
    const client = getClient()
    if (action.payload.add) {
      const response = yield client.mutate({
        mutation: gql`
          ${CREATE_PENDING_ROLE_ASSIGNMENT}
        `,
        variables: {
          assignment: action.payload.add.map(guy => ({
            inviteSrn: action.payload.srn,
            roleSrn: guy.roleSrn,
            scope: guy.scope,
          })),
        },
      })

      const items = _.get(response, [
        'data',
        'CreateSonraiPendingRoleAssignments',
        'items',
      ])

      if (items) {
        items.forEach(response => {
          added.push(response)
        })
      } else if (response.errors) {
        yield put(
          setUserManagementError(
            _.get(
              response.errors,
              [0, 'message'],
              'Error updating pending role assignments'
            )
          )
        )
      }
    }
    if (action.payload.remove) {
      const response = yield client.mutate({
        mutation: gql`
          ${DELETE_PENDING_ROLE_ASSIGNMENT}
        `,
        variables: {
          input: action.payload.remove.map(srn => ({
            srn: srn,
          })),
        },
      })

      const items = _.get(
        response,
        ['data', 'DeleteSonraiPendingRoleAssignments', 'items'],
        false
      )
      if (items) {
        items.forEach(response => {
          if (response.deleted) {
            removed.push(response.srn)
          }
        })
      } else if (response.errors) {
        yield put(
          setUserManagementError(
            _.get(
              response.errors,
              [0, 'message'],
              'Error updating pending role assignments'
            )
          )
        )
      }
    }

    yield put(
      updatePendingRoleAssignmentsSuccess({
        srn: action.payload.srn,
        added: added,
        removed: removed,
      })
    )
  } catch (e) {
    yield put(setUserManagementError(e.message))
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

function* updateUserInvite(action) {
  const UPDATE_USER_INVITE_QUERY = `
    mutation cancelUserInvite($input: [SonraiInviteDeleter!]!) {
      DeleteSonraiInvites(input: $input)
    }
  `
  try {
    const client = getClient()
    const data = yield client.mutate({
      mutation: gql`
        ${UPDATE_USER_INVITE_QUERY}
      `,
      variables: {
        input: { srn: action.payload.srn },
      },
    })
    if (data) {
      yield put(updateUserInviteSuccess())
    }
  } catch (e) {
    yield put(setUserManagementError(e.message))
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

function* cancelUserInvite(action) {
  try {
    const client = getClient()
    const data = yield client.mutate({
      mutation: gql`
        ${CANCEL_SONRAI_INVITE_QUERY}
      `,
      variables: {
        input: { srn: action.payload.srn },
      },
    })

    if (
      _.get(data, ['data', 'DeleteSonraiInvites', 'items', 0, 'deleted'], false)
    ) {
      yield put(cancelUserInviteSuccess({ srn: action.payload.srn }))
    }
  } catch (e) {
    yield put(setUserManagementError(e.message))
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

function* resendUserInvite(action) {
  try {
    const client = getClient()
    const didCancel = yield client.mutate({
      mutation: gql`
        ${CANCEL_SONRAI_INVITE_QUERY}
      `,
      variables: {
        input: { srn: action.payload.srn },
      },
    })

    if (
      _.get(
        didCancel,
        ['data', 'DeleteSonraiInvites', 'items', 0, 'deleted'],
        false
      )
    ) {
      const user = action.payload.pendingGuy.toJS()

      const didReinvite = yield client.mutate({
        mutation: gql`
          ${INVITE_USER_MUTATION}
        `,
        variables: {
          input: {
            email: user.email,
            name: user.email.substring(0, 9),
            pendingRoleAssigners: user.pendingRoleAssignments.items.map(
              assign => {
                return {
                  roleSrn: assign.role.items[0].srn,
                  scope: assign.scope,
                }
              }
            ),
          },
        },
      })
      if (didReinvite) {
        yield put(resendUserInviteSuccess({ srn: action.payload.srn }))
      }
    }
  } catch (e) {
    yield put(setUserManagementError(e.message))
    //eslint-disable-next-line no-console
    console.error('Error saving user profile', e)
  }
}

function* userManagementSaga() {
  yield takeLatest(FETCH_PENDING_USERS, fetchPendingUsers)
  yield takeLatest(FETCH_SONRAI_ROLES, fetchSonraiRoles)
  // yield takeLatest(FETCH_SONRAI_SCOPES, fetchSonraiScopes)
  yield takeLatest(INVITE_USER, inviteUser)
  yield takeLatest(RESEND_USER_INVITE, resendUserInvite)
  yield takeLatest(UPDATE_USER_INVITE, updateUserInvite)
  yield takeLatest(CANCEL_USER_INVITE, cancelUserInvite)
  yield takeLatest(
    UPDATE_PENDING_ROLE_ASSIGNMENTS,
    updatePendingRoleAssignments
  )
}

export default userManagementSaga
