import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Table } from 'reactstrap'

import TextLink from 'components/TextLink'

import FriendlyScope from './FriendlyScope'

const RolesDisplay = ({ data, swimlanes, removeRole, roles }) => {
  return (
    <Table style={{ marginBottom: 0 }}>
      <thead>
        <tr>
          <th style={{ borderTop: 0 }}>Role</th>
          <th style={{ borderTop: 0 }}>Scope</th>
          <th style={{ borderTop: 0 }} />
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.role}</td>
            <td>
              <FriendlyScope
                roleSrn={row.roleSrn}
                scope={row.scope}
                swimlanes={swimlanes}
                roles={roles}
              />
            </td>
            <td>
              <TextLink
                title="Remove role assignment"
                color="primary"
                onClick={
                  removeRole
                    ? () => {
                        removeRole(row)
                      }
                    : undefined
                }
              >
                remove
              </TextLink>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

RolesDisplay.propTypes = {
  data: PropTypes.array,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  removeRole: PropTypes.func,
  roles: ImmutablePropTypes.iterable.isRequired,
}

export default RolesDisplay
export { default as FriendlyScope } from './FriendlyScope'
