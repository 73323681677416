import { fromJS, Map, List, merge } from 'immutable'
import moment from 'moment'
import { handleActions } from 'redux-actions'
import {
  FETCH_USERS,
  FETCH_USERS_SUCCESS,
  FETCH_PENDING_USERS,
  FETCH_PENDING_USERS_SUCCESS,
  INVITE_USER,
  INVITE_USER_SUCCESS,
  RESEND_USER_INVITE,
  RESEND_USER_INVITE_SUCCESS,
  CANCEL_USER_INVITE,
  CANCEL_USER_INVITE_SUCCESS,
  FETCH_SONRAI_ROLES,
  FETCH_SONRAI_ROLES_SUCCESS,
  FETCH_SONRAI_SCOPES,
  FETCH_SONRAI_SCOPES_SUCCESS,
  UPDATE_PENDING_ROLE_ASSIGNMENTS,
  UPDATE_PENDING_ROLE_ASSIGNMENTS_SUCCESS,
  SET_USER_MANAGEMENT_ERROR,
  CLEAR_USER_MANAGEMENT_ERROR,
} from './constants'

const initialState = fromJS({
  userManagementError: null,
  fetchingUsers: false,
  fetchingPendingUsers: false,
  users: null,
  pendingUsers: null,
  invitingUser: false,
  cancellingUserInvite: Map(),
  sonraiRoles: null,
  sonraiScopes: null,
  fetchingSonraiRoles: false,
  fetchingSonraiScopes: false,
  updatingUserInvite: Map(),
  resendingUserInvite: Map(),
})

const userManagementReducer = handleActions(
  {
    [FETCH_USERS]: state => state.set('fetchingUsers', true),
    [FETCH_USERS_SUCCESS]: (state, { payload }) =>
      state.set('fetchingUsers', false).set('users', fromJS(payload.users)),
    [FETCH_PENDING_USERS]: state => state.set('fetchingPendingUsers', true),
    [FETCH_PENDING_USERS_SUCCESS]: (state, { payload }) =>
      state
        .set('fetchingPendingUsers', false)
        .set('pendingUsers', fromJS(payload.users)),
    [INVITE_USER]: state => state.set('invitingUser', true),
    [INVITE_USER_SUCCESS]: (state, { payload }) => {
      if (!payload) {
        return state.set('invitingUser', false)
      }
      return state
        .update('pendingUsers', pendingUsers =>
          pendingUsers.push(fromJS(payload.user))
        )
        .set('invitingUser', false)
    },

    [UPDATE_PENDING_ROLE_ASSIGNMENTS]: (state, { payload }) =>
      state.setIn(['updatingUserInvite', payload.srn], true),
    [UPDATE_PENDING_ROLE_ASSIGNMENTS_SUCCESS]: (state, { payload }) => {
      let newAssignments = List()
      let userIndex = -1
      state.get('pendingUsers').forEach((user, index) => {
        if (user.get('srn') === payload.srn) {
          userIndex = index
          user
            .getIn(['pendingRoleAssignments', 'items'])
            .forEach(assignment => {
              if (!payload.removed.includes(assignment.get('srn'))) {
                newAssignments = newAssignments.push(assignment)
              }
            })
        }
      })
      newAssignments = merge(newAssignments, fromJS(payload.added))
      if (userIndex !== -1) {
        return state
          .setIn(
            ['pendingUsers', userIndex, 'pendingRoleAssignments', 'items'],
            newAssignments
          )
          .deleteIn(['updatingUserInvite', payload.srn])
      } else {
        return state.deleteIn(['updatingUserInvite', payload.srn])
      }
    },
    [RESEND_USER_INVITE]: (state, { payload }) =>
      state.setIn(['resendingUserInvite', payload.srn], true),
    [RESEND_USER_INVITE_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['resendingUserInvite', payload.srn])
        // optimistically update the sent date
        .set(
          'pendingUsers',
          state.get('pendingUsers').map(user => {
            if (payload.srn === user.get('srn')) {
              return user.set(
                'dateSent',
                moment(new Date()).format(moment.HTML5_FMT.DATETIME_LOCAL_MS)
              )
            } else {
              return user
            }
          })
        ),
    [CANCEL_USER_INVITE]: (state, { payload }) =>
      state.setIn(['cancellingUserInvite', payload.srn], true),
    [CANCEL_USER_INVITE_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['cancellingUserInvite', payload.srn])
        .set(
          'pendingUsers',
          state.get('pendingUsers').filter(pu => pu.get('srn') !== payload.srn)
        ),
    [FETCH_SONRAI_ROLES]: state => state.set('fetchingSonraiRoles', true),
    [FETCH_SONRAI_ROLES_SUCCESS]: (state, { payload }) =>
      state
        .set('sonraiRoles', fromJS(payload.roles))
        .set('fetchingSonraiRoles', false),
    [FETCH_SONRAI_SCOPES]: state => state.set('fetchingSonraiScopes', true),
    [FETCH_SONRAI_SCOPES_SUCCESS]: (state, { payload }) =>
      state
        .set('sonraiScopes', fromJS(payload.scopes))
        .set('fetchingSonraiScopes', false),
    [SET_USER_MANAGEMENT_ERROR]: (state, { payload }) =>
      state.set('userManagementError', payload),
    [CLEAR_USER_MANAGEMENT_ERROR]: state =>
      state.set('userManagementError', null),
  },
  initialState
)

export default userManagementReducer
