import { createAction } from 'redux-actions'
import {
  INVITE_USER,
  INVITE_USER_SUCCESS,
  UPDATE_USER_INVITE,
  UPDATE_USER_INVITE_SUCCESS,
  RESEND_USER_INVITE,
  RESEND_USER_INVITE_SUCCESS,
  CANCEL_USER_INVITE,
  CANCEL_USER_INVITE_SUCCESS,
  FETCH_PENDING_USERS,
  FETCH_PENDING_USERS_SUCCESS,
  FETCH_SONRAI_ROLES,
  FETCH_SONRAI_ROLES_SUCCESS,
  FETCH_SONRAI_SCOPES,
  FETCH_SONRAI_SCOPES_SUCCESS,
  UPDATE_PENDING_ROLE_ASSIGNMENTS,
  UPDATE_PENDING_ROLE_ASSIGNMENTS_SUCCESS,
  SET_USER_MANAGEMENT_ERROR,
  CLEAR_USER_MANAGEMENT_ERROR,
} from './constants'

export const fetchPendingUsers = createAction(FETCH_PENDING_USERS)
export const fetchPendingUsersSuccess = createAction(
  FETCH_PENDING_USERS_SUCCESS
)
export const inviteUser = createAction(INVITE_USER)
export const inviteUserSuccess = createAction(INVITE_USER_SUCCESS)
export const updateUserInvite = createAction(UPDATE_USER_INVITE)
export const updateUserInviteSuccess = createAction(UPDATE_USER_INVITE_SUCCESS)
export const updatePendingRoleAssignments = createAction(
  UPDATE_PENDING_ROLE_ASSIGNMENTS
)
export const updatePendingRoleAssignmentsSuccess = createAction(
  UPDATE_PENDING_ROLE_ASSIGNMENTS_SUCCESS
)
export const resendUserInvite = createAction(RESEND_USER_INVITE)
export const resendUserInviteSuccess = createAction(RESEND_USER_INVITE_SUCCESS)
export const cancelUserInvite = createAction(CANCEL_USER_INVITE)
export const cancelUserInviteSuccess = createAction(CANCEL_USER_INVITE_SUCCESS)
export const fetchSonraiRoles = createAction(FETCH_SONRAI_ROLES)
export const fetchSonraiRolesSuccess = createAction(FETCH_SONRAI_ROLES_SUCCESS)
export const fetchSonraiScopes = createAction(FETCH_SONRAI_SCOPES)
export const fetchSonraiScopesSuccess = createAction(
  FETCH_SONRAI_SCOPES_SUCCESS
)

export const setUserManagementError = createAction(SET_USER_MANAGEMENT_ERROR)
export const clearUserManagementError = createAction(
  CLEAR_USER_MANAGEMENT_ERROR
)
