import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { List, fromJS } from 'immutable'
import { connect } from 'react-redux'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import FormLabel from 'components/FormLabel'
import RolesDisplay from 'components/RolesDisplay'
import { selectSonraiUserSrn } from 'containers/UserProfileData/selectors'
import { selectSonraiUsers } from 'containers/SonraiData/selectors'
import {
  selectAllRoles,
  selectSwimlanes,
} from 'containers/SonraiData/selectors'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  FormGroup,
  FormFeedback,
} from 'reactstrap'
import Icon from 'components/Icon'
import SectionHeader from 'components/SectionHeader'
import RoleAssignmentForm from './RoleAssignmentForm'
import _ from 'lodash'
import Error from 'components/Error'
import messages from './messages'

export class AddEditInviteModal extends React.Component {
  styles = {
    table: {
      height: '350px',
    },
    label: {
      fontSize: '1em',
      fontWeight: '400',
      marginTop: '5px',
    },
    banner: {
      width: '100%',
      backgroundColor: 'red',
    },
  }

  constructor(props) {
    super(props)
    this.validateEmail = _.debounce(this.validateEmail, 1000, {
      leading: false,
      trailing: true,
    })
    const roleList = []
    if (props.user) {
      if (props.existingUser) {
        props.user.getIn(['roleAssignments', 'items']).forEach(assignment => {
          roleList.push({
            role: assignment.getIn(['role', 'items', 0, 'name']),
            roleSrn: assignment.getIn(['role', 'items', 0, 'srn']),
            scope: assignment.get('scope'),
          })
        })
      } else {
        props.user
          .getIn(['pendingRoleAssignments', 'items'])
          .forEach(assignment => {
            roleList.push({
              role: assignment.getIn(['role', 'items', 0, 'name']),
              roleSrn: assignment.getIn(['role', 'items', 0, 'srn']),
              scope: assignment.get('scope'),
            })
          })
      }
    }
    this.state = {
      roleOption: null,
      emailIsValid: true,
      isValid: true,
      emailMsg: null,
      scopeOptions: [],
      scopeInputValue: '',
      existingScopeMsg: null,
      newEmail: '',
      newName: '',
      roleData: fromJS(roleList),
      selectedRoles: List(),
      shouldClear: false,
      showRoleForm: false,
    }
  }

  clearForm = () => {
    this.setState({
      roleOption: null,
      scopeInputValue: '',
      existingScopeMsg: null,
      scopeOptions: [],
      newEmail: '',
      newName: '',
      roleData: List(),
      selectedRoles: List(),
      shouldClear: false,
      showRoleForm: false,
    })
  }

  showRoleForm = () => {
    this.setState({
      showRoleForm: true,
    })
  }

  createOption = label => ({
    label,
    value: label,
  })

  componentDidUpdate() {
    const pending =
      this.props.updatingUserInvite !== undefined
        ? this.props.updatingUserInvite
        : this.props.invitingUser

    if (this.state.shouldClear && !pending) {
      this.clearForm()
      if (this.props.isOpen && !this.props.error) {
        this.props.toggle()
      }
    }
  }

  showExistingScopeMsg = scopeGuy => {
    this.setState({
      existingScopeMsg: `Role with scope of "${scopeGuy}" already exists...`,
    })
    setTimeout(() => {
      this.setState({
        existingScopeMsg: null,
      })
    }, 3000)
  }

  handleScopeKeyDown = event => {
    const { scopeInputValue, scopeOptions } = this.state
    if (!scopeInputValue) return
    if (
      scopeOptions &&
      scopeOptions.find(scopes => scopes.label === scopeInputValue)
    ) {
      this.showExistingScopeMsg(scopeInputValue)
      this.setState({ scopeInputValue: '' })
      return
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({
          scopeInputValue: '',
          scopeOptions: [...scopeOptions, this.createOption(scopeInputValue)],
        })
        event.preventDefault()
    }
  }

  validateEmail = email => {
    // eslint-disable-next-line
    const validFormatting = /^([a-zA-Z0-9+_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
      email
    )
    const isExisting = this.props.unavailableEmails.existing.includes(email)
    const isPending = this.props.unavailableEmails.pending.includes(email)

    if (!validFormatting || isExisting || isPending) {
      this.setState({
        emailIsValid: false,
        emailMsg: !validFormatting
          ? 'Please enter a valid email.'
          : isExisting
          ? 'This email address is already associated with a user.'
          : isPending
          ? 'This email address already has a pending invite.'
          : null,
      })
    } else {
      this.setState({
        emailIsValid: true,
        emailMsg: null,
      })
    }
  }

  handleEmailChange = bruh => {
    this.validateEmail(bruh.target.value)
    this.setState({ newEmail: bruh.target.value })
  }

  handleNameChange = bruh => {
    this.setState({ newName: bruh.target.value })
  }

  handleRoleOptionChange = guy => {
    this.setState({ roleOption: guy })
  }

  handleScopeInputChange = inputValue => {
    this.setState({ scopeInputValue: inputValue })
  }

  handleScopeOptionChange = (guy, type) => {
    if (
      (type.action = 'remove-value') &&
      this.state.scopeOptions.length === 1
    ) {
      this.setState({ scopeOptions: [] })
    } else {
      this.setState({ scopeOptions: guy })
    }
  }

  handleSelectionChange = event => {
    const rows = event.api.getSelectedRows() || []
    this.setState({ selectedRoles: fromJS(rows) })
  }

  quickCheckValidEmail = () => {
    // eslint-disable-next-line
    const validFormatting = /^([a-zA-Z0-9+_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
      this.state.newEmail
    )
    const isExisting = this.props.unavailableEmails.existing.includes(
      this.state.newEmail
    )
    const isPending = this.props.unavailableEmails.pending.includes(
      this.state.newEmail
    )
    if (!validFormatting || isExisting || isPending) {
      return false
    } else {
      return true
    }
  }

  submitInvite = () => {
    if (this.quickCheckValidEmail()) {
      this.props.inviteUser({
        name: this.state.newName,
        email: this.state.newEmail,
        roleAssignments: this.state.roleData
          .toJS()
          .map(role => ({ role: role.roleSrn, scope: role.scope })),
      })
      this.setState({ shouldClear: true })
    }
  }

  saveInvite = () => {
    // remove the goons and add the coolguys
    const getOuttaHereDumbos = []
    const urCoolLetsAddYa = []
    const existingAssignments = this.props.existingUser
      ? this.props.user.getIn(['roleAssignments', 'items'], List())
      : this.props.user.getIn(['pendingRoleAssignments', 'items'], List())

    this.state.roleData.forEach(assState => {
      if (
        !existingAssignments.find(
          ea =>
            assState.get('roleSrn') ===
              ea.getIn(['role', 'items', 0, 'srn'], null) &&
            assState.get('scope') === ea.getIn(['scope'], null)
        )
      ) {
        urCoolLetsAddYa.push(assState.toJS())
      }
    })

    existingAssignments.forEach(existing => {
      if (
        !this.state.roleData.find(
          data =>
            data.get('roleSrn') ===
              existing.getIn(['role', 'items', 0, 'srn'], null) &&
            data.get('scope') === existing.getIn(['scope'], null)
        )
      ) {
        getOuttaHereDumbos.push(existing.get('srn'))
      }
    })

    this.props.updatePendingRoleAssignments({
      srn: this.props.user.get('srn'),
      remove: getOuttaHereDumbos,
      add: urCoolLetsAddYa,
    })
    this.setState({ shouldClear: true })
  }

  getRoleOptions = () => {
    return this.props.roles
      .get('data')
      .toJS()
      .map(role => {
        return {
          label: role.name,
          value: role.srn,
        }
      })
  }

  // TOREMOVE
  addRole = () => {
    const thingsToAdd = []
    if (this.state.roleOption && this.state.scopeOptions) {
      this.state.scopeOptions.forEach(scope => {
        if (
          this.state.roleData.find(
            dabba =>
              dabba.roleSrn === this.state.roleOption.value &&
              dabba.scope === scope.value
          )
        ) {
          return
        } else {
          thingsToAdd.push({
            role: this.state.roleOption.label,
            roleSrn: this.state.roleOption.value,
            scope: scope.value,
          })
        }
      })
      this.setState(prevState => ({
        roleData: prevState.roleData.concat(fromJS(thingsToAdd)),
      }))
      this.setState({ roleOption: null, scopeOptions: [] })
    }
  }

  removeRole = data => {
    this.setState(prevState => ({
      roleData: prevState.roleData.filterNot(
        dabba =>
          dabba.get('scope') === data.scope &&
          dabba.get('roleSrn') === data.roleSrn
      ),
    }))
  }

  addToRoleData = thingsToAdd => {
    this.setState(prevState => ({
      roleData: prevState.roleData.concat(fromJS(thingsToAdd)),
      showRoleForm: false,
      isValid: true,
    }))
  }

  getEditBody = () => {
    const data = this.state.roleData.toJS().map(role => {
      return {
        ...role,
        removeRole: 'remove',
      }
    })

    return (
      <div>
        <SectionHeader small>Assigned Roles</SectionHeader>
        <div style={this.styles.table}>
          <RolesDisplay
            data={data}
            swimlanes={this.props.swimlanes}
            removeRole={this.removeRole}
            roles={this.props.roles}
          />
        </div>
        <div>
          <SectionHeader>
            <FormattedMessage {...messages.AddRoleAssignmentHeader} />
          </SectionHeader>

          <RoleAssignmentForm
            restrictToSwimlanes={this.props.restrictToSwimlanes}
            selectedRoles={this.state.roleData.toJS()}
            addToRoleData={this.addToRoleData}
            roles={this.props.roles}
            roleData={this.state.roleData}
            user={this.props.user}
            swimlanes={this.props.swimlanes}
            updatingUserInvite={this.props.updatingUserInvite}
            onRoleAssignmentSelectionChange={
              this.onRoleAssignmentSelectionChange
            }
            allUsers={this.props.allUsers}
            currentUser={this.props.currentUser}
          />
        </div>
      </div>
    )
  }

  getNoRows = () => {
    return '<div>No roles added</div>'
  }

  onRoleAssignmentSelectionChange = params => {
    if (params) {
      this.setState({ isValid: false })
    } else {
      this.setState({ isValid: true })
    }
  }

  getAddBody = () => {
    const data = (this.state.roleData ? this.state.roleData.toJS() : []).map(
      role => {
        return {
          ...role,
          removeRole: 'remove',
        }
      }
    )

    return (
      <div>
        <FormLabel required style={{ margin: 0 }}>
          Email Address
        </FormLabel>
        <FormGroup>
          <Input
            value={this.state.newEmail}
            onChange={this.handleEmailChange}
            disabled={this.props.invitingUser}
            invalid={this.state.newEmail !== '' && !this.state.emailIsValid}
          />
          <FormFeedback>{this.state.emailMsg}</FormFeedback>
        </FormGroup>

        <FormLabel style={{ margin: 0 }}>Name</FormLabel>
        <FormGroup>
          <Input
            value={this.state.newName}
            onChange={this.handleNameChange}
            disabled={this.props.invitingUser}
            placeholder={this.state.newEmail}
          />
        </FormGroup>
        {this.props.canViewData && (
          <Fragment>
            <div style={{ margin: '0rem 0rem 0.5rem 0rem' }}>
              {data.length === 0 && (
                <small>You must assign at least one role.</small>
              )}
            </div>

            {data.length > 0 && (
              <RolesDisplay
                data={data}
                swimlanes={this.props.swimlanes}
                removeRole={this.removeRole}
                roles={this.props.roles}
              />
            )}

            {data.length > 0 && !this.state.showRoleForm && (
              <TextLink
                color="primary"
                style={{ marginLeft: '0.5em' }}
                onClick={this.showRoleForm}
              >
                <Icon fa name="plus" /> Add Another Role
              </TextLink>
            )}

            {(data.length === 0 || this.state.showRoleForm) && (
              <RoleAssignmentForm
                restrictToSwimlanes={this.props.restrictToSwimlanes}
                noTitle
                addToRoleData={this.addToRoleData}
                roles={this.props.roles}
                roleData={this.state.roleData}
                user={this.props.user}
                swimlanes={this.props.swimlanes}
                updatingUserInvite={this.props.updatingUserInvite}
                selectedRoles={this.state.roleData.toJS()}
                closeRoleForm={() =>
                  this.setState({ showRoleForm: false, isValid: true })
                }
                onRoleAssignmentSelectionChange={
                  this.onRoleAssignmentSelectionChange
                }
                allUsers={this.props.allUsers}
                currentUser={this.props.currentUser}
              />
            )}
          </Fragment>
        )}
      </div>
    )
  }

  compareRollyBois = user => {
    const roleSize = this.props.existingUser
      ? user.getIn(['roleAssignments', 'items'], List()).size
      : user.getIn(['pendingRoleAssignments', 'items'], List()).size
    // must be diff
    if (roleSize !== this.state.roleData.size) {
      return true
    } else {
      // loop em and check if they are the same
      let isDifferent = false

      const existingBoiz = (this.props.existingUser
        ? user.getIn(['roleAssignments', 'items'], List())
        : user.getIn(['pendingRoleAssignments', 'items'], List())
      ).map(assignment => ({
        roleSrn: assignment.getIn(['role', 'items', 0, 'srn']),
        scope: assignment.get('scope'),
      }))

      existingBoiz.forEach(roleboi => {
        let exists = false
        this.state.roleData.forEach(otherguy => {
          if (
            roleboi.roleSrn === otherguy.get('roleSrn') &&
            roleboi.scope === otherguy.get('scope')
          ) {
            exists = true
          }
        })
        if (!exists) {
          isDifferent = true
        }
      })

      return isDifferent
    }
  }

  render() {
    const { toggle, isOpen, user, error } = this.props

    return (
      <Modal toggle={toggle} isOpen={isOpen} style={{ minWidth: '700px' }}>
        <ModalHeader toggle={toggle}>
          {user
            ? `${
                this.props.existingUser ? `User` : `Pending User`
              } ${this.props.user.get('email')}`
            : 'Invite New User'}
        </ModalHeader>
        <ModalBody>
          {!error ? (
            user ? (
              this.getEditBody()
            ) : (
              this.getAddBody()
            )
          ) : (
            <Error description={error} />
          )}
        </ModalBody>
        <ModalFooter>
          <TextLink
            style={{ marginTop: '0.5em' }}
            color="primary"
            onClick={toggle}
          >
            Cancel
          </TextLink>
          {user ? (
            <Button
              disabled={
                this.props.updatingUserInvite ||
                !this.compareRollyBois(user) ||
                !this.state.isValid
              }
              onClick={this.saveInvite}
              color="primary"
            >
              Save Roles
            </Button>
          ) : (
            <Button
              disabled={
                !this.state.newEmail ||
                this.props.invitingUser ||
                !this.state.emailIsValid ||
                !this.state.isValid
              }
              onClick={this.submitInvite}
              color="primary"
            >
              Send Invite
              {this.props.invitingUser && (
                <div
                  style={{
                    display: 'inline-block',
                  }}
                >
                  <Icon fa name="sync" spin />
                </div>
              )}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = createStructuredSelector({
  currentUser: selectSonraiUserSrn,
  allUsers: selectSonraiUsers,
  roles: selectAllRoles,
  swimlanes: selectSwimlanes,
})

const withConnect = connect(mapStateToProps)

AddEditInviteModal.propTypes = {
  unavailableEmails: PropTypes.object,
  user: ImmutablePropTypes.map,
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  existingUser: PropTypes.bool,
  roles: ImmutablePropTypes.map,
  inviteUser: PropTypes.func,
  invitingUser: PropTypes.bool,
  swimlanes: ImmutablePropTypes.map,
  updatePendingRoleAssignments: PropTypes.func,
  updatingUserInvite: PropTypes.bool,
  canViewData: PropTypes.bool,
  restrictToSwimlanes: PropTypes.bool,
  error: PropTypes.string,
  allUsers: ImmutablePropTypes.iterable,
  currentUser: PropTypes.string,
}

export default compose(withConnect)(AddEditInviteModal)
