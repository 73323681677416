export const FETCH_USERS = 'app/UserManagement/FETCH_USERS'
export const FETCH_USERS_SUCCESS = 'app/UserManagement/FETCH_USERS_SUCCESS'
export const FETCH_PENDING_USERS = 'app/UserManagement/FETCH_PENDING_USERS'
export const FETCH_PENDING_USERS_SUCCESS =
  'app/UserManagement/FETCH_PENDING_USERS_SUCCESS'
export const INVITE_USER = 'app/UserManagement/INVITE_USER'
export const INVITE_USER_SUCCESS = 'app/UserManagement/INVITE_USER_SUCCESS'
export const UPDATE_USER_INVITE = 'app/UserManagement/UPDATE_USER_INVITE'
export const UPDATE_USER_INVITE_SUCCESS =
  'app/UserManagement/UPDATE_USER_INVITE_SUCCESS'
export const RESEND_USER_INVITE = 'app/UserManagement/RESEND_USER_INVITE'
export const RESEND_USER_INVITE_SUCCESS =
  'app/UserManagement/RESEND_USER_INVITE_SUCCESS'
export const CANCEL_USER_INVITE = 'app/UserManagement/CANCEL_USER_INVITE'
export const CANCEL_USER_INVITE_SUCCESS =
  'app/UserManagement/CANCEL_USER_INVITE_SUCCESS'
export const FETCH_SONRAI_ROLES = 'app/UserManagement/FETCH_SONRAI_ROLES'
export const FETCH_SONRAI_ROLES_SUCCESS =
  'app/UserManagement/FETCH_SONRAI_ROLES_SUCCESS'
export const FETCH_SONRAI_SCOPES = 'app/UserManagement/FETCH_SONRAI_SCOPES'
export const FETCH_SONRAI_SCOPES_SUCCESS =
  'app/UserManagement/FETCH_SONRAI_SCOPES_SUCCESS'
export const UPDATE_PENDING_ROLE_ASSIGNMENTS =
  'app/UserManagement/UPDATE_PENDING_ROLE_ASSIGNMENTS'
export const UPDATE_PENDING_ROLE_ASSIGNMENTS_SUCCESS =
  'app/UserManagement/UPDATE_PENDING_ROLE_ASSIGNMENTS_SUCCESS'
export const SET_USER_MANAGEMENT_ERROR =
  'app/UserManagement/SET_USER_MANAGEMENT_ERROR'
export const CLEAR_USER_MANAGEMENT_ERROR =
  'app/UserManagement/CLEAR_USER_MANAGEMENT_ERROR'
