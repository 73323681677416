import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map, Set } from 'immutable'
import { FormattedMessage } from 'react-intl'
import { FormGroup } from 'reactstrap'

import Select from 'components/Select'
import permissionChecker from 'containers/PermissionChecker'
import Button from 'components/Button'
import Icon from 'components/Icon'
import Tooltip from 'components/Tooltip'
import ScopeAssignmentForm from 'components/ScopeAssignmentForm'
import FormLabel from 'components/FormLabel'
import { getCurrentOrg } from 'auth/current-org'
import { isPlatformRole } from 'utils/sonraiUtils'
import _ from 'lodash'
import messages from './messages'
import TextLink from 'components/TextLink'

function getRoleOptions({ roles, selectedRoles, restrictToSwimlanes }) {
  const platformRoles = []
  const swimlaneRoles = []

  if (restrictToSwimlanes) {
    roles.get('data', List()).forEach(role => {
      if (!isPlatformRole(role)) {
        swimlaneRoles.push({
          label: role.get('name'),
          value: role.get('srn'),
        })
      }
    })

    return swimlaneRoles
  }

  roles.get('data', List()).forEach(role => {
    if (isPlatformRole(role)) {
      platformRoles.push({
        label: role.get('name'),
        value: role.get('srn'),
      })
    } else {
      swimlaneRoles.push({
        label: role.get('name'),
        value: role.get('srn'),
      })
    }
  })

  return platformRoles
    .filter(
      role =>
        selectedRoles.findIndex(selected => selected.roleSrn === role.value) ===
        -1
    )
    .concat(swimlaneRoles)
}

function getSelectedRole({ roles, roleOption }) {
  if (!roleOption) {
    return null // TODO is this right?
  }

  // find the role that is selected
  const role = roles
    .get('data', List())
    .find(role => role.get('srn') === roleOption.value)

  if (!role) {
    // eslint-disable-next-line no-console
    console.warn(
      'could not find role ' +
        roleOption.value +
        ' in list of roles ' +
        roles.toJS()
    )
    return null
  }
  return role
}

function getRoleAssignmentsForUser({ user, role }) {
  if (!user) {
    return []
  }
  const assignments = role
    .getIn(['roleAssignments', 'items'], List())
    .filter(assignment => {
      // we might wanna consider moving this to graphql
      const assUser = assignment.getIn(['user', 'items', 0], Map())
      return assUser.get('srn') === user.get('srn')
    })
  return assignments
}

const styles = {
  inlineform: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
}

export function RoleAssignmentForm(props) {
  const [roleOption, handleRoleOptionChange] = useState(null)
  const [scopeInputValue, handleScopeInputChange] = useState(null)
  const [platformPerm, handlePlatformPermClick] = useState(false)

  const addRole = () => {
    const thingsToAdd = []
    if (roleOption) {
      if (scopeInputValue) {
        scopeInputValue.forEach(scope => {
          if (scope.value !== 'All') {
            thingsToAdd.push({
              role: roleOption.label,
              roleSrn: roleOption.value,
              scope: scope.value,
            })
          }
        })
      }

      const role = getSelectedRole({ ...props, roleOption })

      if (role && (platformPerm || isPlatformRole(role))) {
        const specialOrgScope = `/org/${getCurrentOrg()}/*`
        thingsToAdd.push({
          role: roleOption.label,
          roleSrn: roleOption.value,
          scope: specialOrgScope,
        })
      }

      props.addToRoleData(thingsToAdd)
      handleScopeInputChange(null)
      handleRoleOptionChange(null)
      handlePlatformPermClick(false)
    }
  }

  const role = getSelectedRole({ ...props, roleOption })
  const scopeOptions = []
  const hasNonSwimlanePerms = role ? isPlatformRole(role) : false

  if (role != null) {
    const roleAssignments = getRoleAssignmentsForUser({ ...props, role })
    const userScopes = new Set(roleAssignments.map(ass => ass.get('scope')))

    if (!hasNonSwimlanePerms) {
      Object.values(props.swimlanes.toJS())
        .filter(({ srn }) => !userScopes.includes(srn))
        .map(({ resourceId: value, title: label }) => ({ value, label }))
        .forEach(option => scopeOptions.push(option))
    }
  }

  const getRoleLabel = roleSrn => {
    if (!roleSrn) {
      return
    }
    const role = props.roles
      .get('data', List())
      .find(role => role.get('srn') === roleSrn)

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto auto',
          gridColumnGap: '0.3em',
        }}
      >
        <span style={{ paddingRight: '0.3em' }}>{role.get('name')}</span>
        <span style={{ fontStyle: 'italic' }}>
          ({isPlatformRole(role) ? 'Platform Role' : 'Swimlane Role'})
        </span>
        <Tooltip
          anchor={<Icon fa name="info-circle" style={{ fontSize: '1.2rem' }} />}
          tooltipContent={role.get('description')}
        />
      </div>
    )
  }

  const getValidSwimlaneScopes = () => {
    if (platformPerm) {
      return Map()
    }

    const matchingAssignments =
      props.selectedRoles.filter(
        assignment => assignment.roleSrn === role.get('srn')
      ) || []

    if (matchingAssignments.length === 0) {
      return props.swimlanes
    }

    return props.swimlanes.filter(swimlane => {
      const resourceId = swimlane.get('resourceId')
      return !matchingAssignments.find(
        assignment => assignment.scope === resourceId
      )
    })
  }

  const isDisabled = () => {
    if (role && !props.updatingUserInvite) {
      if (hasNonSwimlanePerms) {
        if (roleOption) {
          return false
        }
      } else {
        if (!_.isEmpty(scopeInputValue) || platformPerm) {
          return false
        }
      }
    }
    return true
  }

  const clearRoleForm = () => {
    handleRoleOptionChange(null)
    handleScopeInputChange(null)
    handlePlatformPermClick(false)
    props.closeRoleForm()
  }

  const onOptionChange = params => {
    handleRoleOptionChange(params)
    props.onRoleAssignmentSelectionChange(params)
  }

  const userRoleAssignments = props.allUsers.getIn(
    [props.currentUser || '', 'roleAssignments', 'items'],
    List()
  )

  const userOrg = getCurrentOrg()
  const specialOrgScope = `/org/${userOrg}/*`

  const userHasSpecialOrgScope = !!userRoleAssignments.find(role => {
    const permissions = role.getIn(['role', 'items', 0, 'permissions'])
    return (
      (permissions.includes('edit.roleassignments') ||
        permissions.includes('*')) &&
      role.get('scope') === specialOrgScope
    )
  })

  return (
    <div style={props.style}>
      <FormGroup style={styles.inlineform}>
        <FormLabel required style={{ margin: '0rem 0.5rem 0rem 0rem' }}>
          <FormattedMessage {...messages.RoleLable} />
        </FormLabel>

        <Select
          isClearable
          idisabled={props.roles.get('loading') || props.updatingUserInvite}
          placeholder={'Select a Role...'}
          onChange={onOptionChange}
          options={getRoleOptions(props)}
          value={roleOption}
          getOptionValue={option => option.value}
          getOptionLabel={option => getRoleLabel(option.value)}
        />
      </FormGroup>

      {roleOption && !hasNonSwimlanePerms && (
        <ScopeAssignmentForm
          swimlanes={getValidSwimlaneScopes()}
          scopeInputValue={scopeInputValue}
          platformPerm={platformPerm}
          handleScopeInputChange={handleScopeInputChange}
          handlePlatformPermClick={handlePlatformPermClick}
          canSelectAllSwimlanes={userHasSpecialOrgScope}
          isDisabled={false}
        />
      )}

      <FormGroup>
        <Button onClick={addRole} disabled={isDisabled()} color="primary">
          Add Role
        </Button>
        {props.closeRoleForm && !props.roleData.isEmpty() && (
          <TextLink
            color="primary"
            style={{
              marginLeft: '0.5rem',
            }}
            onClick={() => clearRoleForm()}
          >
            Close Role Form
          </TextLink>
        )}
      </FormGroup>
    </div>
  )
}

RoleAssignmentForm.defaultProps = {
  style: {},
}

RoleAssignmentForm.propTypes = {
  // these props are all passed from parent
  addToRoleData: PropTypes.func,
  roles: ImmutablePropTypes.map.isRequired,
  selectedRoles: PropTypes.array,
  style: PropTypes.object,
  swimlanes: ImmutablePropTypes.map,
  closeRoleForm: PropTypes.func,
  roleData: ImmutablePropTypes.iterable,
  onRoleAssignmentSelectionChange: PropTypes.func,
  currentUser: PropTypes.string,
  allUsers: ImmutablePropTypes.iterable,

  //Used in getRoleAssignmentsForUser
  user: ImmutablePropTypes.map, //eslint-disable-line react/no-unused-prop-types
  updatingUserInvite: PropTypes.bool,
  restrictToSwimlanes: PropTypes.bool,
}

export default permissionChecker(RoleAssignmentForm)
