import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import qs from 'query-string'

import TextLink from 'components/TextLink'
import { isPlatformRole } from 'utils/sonraiUtils'

const FriendlyScope = ({ scope, roles, swimlanes, roleSrn }) => {
  if (!scope.includes('/Swimlane')) {
    const role = roles
      .get('data', List())
      .find(role => role.get('srn') === roleSrn)

    if (!role) {
      return scope
    }

    const roleIsPlatform = isPlatformRole(role)
    if (roleIsPlatform) {
      return <span style={{ fontStyle: 'italic' }}>(Platform)</span>
    } else {
      return <span style={{ fontStyle: 'italic' }}>(All Swimlanes)</span>
    }
  }

  const swimlane = swimlanes.find(
    swimlane => swimlane.get('resourceId') === scope
  )

  if (!swimlane) {
    return scope
  }

  return (
    <TextLink
      color="primary"
      to={{
        pathname: '/App/SwimlaneDetails',
        search: qs.stringify({
          srn: swimlane.get('srn'),
        }),
      }}
    >
      {swimlane.get('title')}
    </TextLink>
  )
}

FriendlyScope.propTypes = {
  scope: PropTypes.string,
  roleSrn: PropTypes.string,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  roles: ImmutablePropTypes.iterable.isRequired,
}

export default FriendlyScope
