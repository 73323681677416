import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'

import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import qs from 'query-string'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import {
  selectFetchingPendingUsers,
  selectPendingUsers,
  selectSonraiScopes,
  selectInvitingUser,
  selectUpdatingUserInvite,
  selectCancellingUserInvite,
  selectResendingUserInvite,
  selectUserManagementError,
} from './selectors'
import {
  selectSonraiUsers,
  selectSonraiUsersLoading,
  selectUpdatingUserRoles,
} from 'containers/SonraiData/selectors'
import {
  fetchPendingUsers,
  inviteUser,
  cancelUserInvite,
  updateUserInvite,
  resendUserInvite,
  updatePendingRoleAssignments,
  clearUserManagementError,
} from './actions'
import userManagementSaga from './sagas'
import userManagementReducer from './reducer'
import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'

import SectionHeader from 'components/SectionHeader'
import permissionChecker from 'containers/PermissionChecker'
import RedirectWithoutPermission from 'containers/PermissionChecker/RedirectWithoutPermission'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import DataTable from 'components/DataTable'
import Button from 'components/Button'
import DynamicFormattedMessage from 'components/DynamicFormattedMessage'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import BorderedCard from 'components/BorderedCard'
import TextLink from 'components/TextLink'
import UserWidget from 'components/UserWidget'
import Icon from 'components/Icon'
import AddEditInviteModal from './AddEditInviteModal'
import moment from 'moment'
import { stripTags } from 'utils/sonraiUtils'

export class UserManagement extends React.Component {
  styles = {
    container: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      padding: '1rem',
      width: '100%',
      height: '100%',
    },
    doubleGuy: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      alignItems: 'center',
    },
    searchGuy: {
      padding: '1rem 0px',
    },
    keepRight: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    body: {},
    userTable: {
      gridArea: 'user',
      marginBottom: '1rem',
    },
    pendingTable: {
      gridArea: 'pending',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
    },
    title: {
      fontSize: '1.2em',
      marginTop: '1.6em',
    },
  }

  constructor(props) {
    super(props)
    if (props.pendingUsers.isEmpty()) {
      props.fetchPendingUsers()
    }
    this.state = {
      addEditModalIsOpen: false,
      pendingUser: null,
      THE_GRID: null,
    }
  }

  setPendingUser = user => {
    this.setState({
      pendingUser: user,
    })
  }

  closeModal = () => {
    if (this.props.userManagementError) {
      this.props.clearUserManagementError()
    }
    this.setState({ pendingUser: null, addEditModalIsOpen: false })
  }

  openAddEditModal = () => {
    this.setState({ addEditModalIsOpen: true })
  }

  getUniqueStuff = assignments => {
    const roles = []
    const scopes = []
    assignments.toJS().items.forEach(assignment => {
      if (!scopes.includes(assignment.scope)) {
        scopes.push(assignment.scope)
      }
      assignment.role.items.forEach(role => {
        if (!roles.includes(role.srn)) {
          roles.push(role.srn)
        }
      })
    })
    return { roles: roles.length, scopes: scopes.length }
  }

  buildUserList = () => {
    const userList = []
    this.props.users.forEach(user => {
      const uniques = this.getUniqueStuff(user.get('roleAssignments'))
      userList.push({
        name: stripTags(user.get('name')),
        srn: user.get('srn'),
        email: stripTags(user.get('email')),
        lastLogin: user.get('lastLogin'),
        status: user.get('isActive'),
        uniqueRoles: uniques.roles,
        uniqueScopes: uniques.scopes,
      })
    })
    return userList
  }

  buildPendingList = () => {
    const pendingList = []
    this.props.pendingUsers.toJS().forEach(invitedDude => {
      pendingList.push({
        email: stripTags(invitedDude.email),
        dateSent: invitedDude.dateSent,
        isPending: invitedDude.isPending,
        expiry: invitedDude.expiry,
        viewEdit: invitedDude.srn,
        cancel: invitedDude.srn,
        resend: invitedDude.srn,
        resourceId: invitedDude.resourceId,
      })
    })
    return pendingList
  }

  viewUser = data => {
    let foundUser = null
    this.props.users.forEach(user => {
      if (user.get('email') === data.email) {
        foundUser = user.set()
      }
    })

    if (foundUser) {
      this.props.push({
        pathname: '/App/UserManagement/User',
        search: qs.stringify({
          srn: foundUser.get('srn'),
        }),
      })
    }
  }

  setApi = api => {
    this.setState({ THE_GRID: api })
  }

  renderUserTable = () => {
    if (this.props.usersLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    } else {
      const users = this.buildUserList()

      return (
        <DataTable
          onDoubleClickRow={this.viewUser}
          autosize={false}
          data={users}
          noTableActions
          customColumnConfig={{
            name: {
              headerName: 'Name',
              cellRendererFramework: params => {
                return <UserWidget srn={params.data.srn} table />
              },
            },
            email: {
              flex: 1,
            },
            lastLogin: {
              headerName: 'Last Login',
              cellRendererFramework: params => {
                const date = moment(params.value)
                if (date.isValid()) {
                  return date.format('ll')
                } else {
                  return '-'
                }
              },
            },
            srn: {
              hide: true,
            },
          }}
        />
      )
    }
  }

  cancelInvite = srn => {
    this.props.cancelUserInvite({ srn: srn })
  }

  resendInvite = srn => {
    this.props.pendingUsers.forEach(user => {
      if (user.get('srn') === srn) {
        this.props.resendUserInvite({ srn: srn, pendingGuy: user })
      }
    })
  }

  viewRoles = value => {
    this.props.pendingUsers.forEach(user => {
      if (user.get('srn') === value) {
        this.setPendingUser(user)
        this.openAddEditModal()
      }
    })
  }

  getAllEmails = () => {
    const existingEmails = []
    const pendingEmails = []
    this.props.users.forEach(user => {
      existingEmails.push(user.get('email'))
    })

    this.props.pendingUsers.forEach(pender => {
      pendingEmails.push(pender.get('email'))
    })

    return { existing: existingEmails, pending: pendingEmails }
  }

  renderPendingUsers = () => {
    if (this.props.fetchingPendingUsers) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    } else {
      const pending = this.buildPendingList()
      const cantEditAnyUsers =
        undefined ===
        pending.find(invitedDude => {
          let canInv = false
          canInv =
            this.props.userHasPermission({
              permissionName: 'edit.users',
              resourceId: invitedDude.resourceId,
            }) ||
            this.props.userHasPermission({
              permissionName: 'edit.invites',
              resourceId: invitedDude.resourceId,
            })
          return canInv
        })
      if (this.state.THE_GRID) {
        this.state.THE_GRID.api.redrawRows()
      }
      const config = {
        resourceId: {
          hide: true,
        },
        email: {
          flex: 1,
        },
        viewEdit: {
          hide: cantEditAnyUsers,
          aggFunc: null,
          headerName: '',
          width: 200,
          cellRendererFramework: params => {
            return (
              <WithPermission
                permissionName="edit.users"
                resourceId={params.data.resourceId}
              >
                <TextLink
                  color="primary"
                  onClick={() => {
                    this.viewRoles(params.value)
                  }}
                  disabled={this.props.cancellingUserInvites.get(params.value)}
                >
                  <FormattedMessage {...messages.EditAssignments} />
                </TextLink>
              </WithPermission>
            )
          },
        },
        cancel: {
          hide: cantEditAnyUsers,
          aggFunc: null,
          headerName: '',
          enableRowGroup: false,
          menuTabs: [],
          suppressMenu: true,
          width: 140,
          cellRendererFramework: params => {
            return (
              <WithPermission
                multiPermissions={[
                  {
                    permissionName: 'edit.users',
                    resourceId: params.data.resourceId,
                  },
                  {
                    permissionName: 'edit.invites',
                    resourceId: params.data.resourceId,
                  },
                ]}
              >
                {this.props.cancellingUserInvites.get(params.value) ? (
                  <div>
                    <TextLink color="primary" disabled={true}>
                      <FormattedMessage {...messages.CancellingInvite} />
                    </TextLink>
                    <span style={{ marginLeft: '5px', fontSize: '0.9em' }}>
                      <Icon fa name="sync" spin />
                    </span>
                  </div>
                ) : (
                  <TextLink
                    color="primary"
                    onClick={() => {
                      this.cancelInvite(params.value)
                    }}
                    disabled={this.props.resendingUserInvite.get(params.value)}
                  >
                    <FormattedMessage {...messages.CancelInvite} />
                  </TextLink>
                )}
              </WithPermission>
            )
          },
        },
        resend: {
          hide: cantEditAnyUsers,
          aggFunc: null,
          headerName: '',
          enableRowGroup: false,
          menuTabs: [],
          suppressMenu: true,
          width: 190,
          cellRendererFramework: params => {
            return (
              <WithPermission
                permissionName="edit.users"
                resourceId={params.data.resourceId}
              >
                {this.props.resendingUserInvite.get(params.value) ? (
                  <div>
                    <TextLink color="primary" disabled={true}>
                      <FormattedMessage {...messages.ResendingInvite} />
                    </TextLink>
                    <span style={{ marginLeft: '5px', fontSize: '0.9em' }}>
                      <Icon fa name="sync" spin />
                    </span>
                  </div>
                ) : (
                  <TextLink
                    color="primary"
                    onClick={() => {
                      this.resendInvite(params.value)
                    }}
                    disabled={this.props.cancellingUserInvites.get(
                      params.value
                    )}
                  >
                    <FormattedMessage {...messages.ResendInvite} />
                  </TextLink>
                )}
              </WithPermission>
            )
          },
        },
        dateSent: {
          headerName: 'Date Sent',
          cellRendererFramework: params => {
            const date = moment(params.value)
            if (date.isValid()) {
              return date.format('ll')
            } else {
              return '-'
            }
          },
        },
        isPending: {
          headerName: 'Active',
        },
        expiry: {
          headerName: 'Expiry Date',
          cellRendererFramework: params => {
            const date = moment(params.value)
            if (date.isValid()) {
              return date.format('ll')
            } else {
              return '-'
            }
          },
        },
      }
      return (
        <DataTable
          customColumnConfig={config}
          data={pending}
          autosize={false}
          noTableActions
          onLoad={this.setApi}
        />
      )
    }
  }

  render() {
    const canViewData = this.props.userHasPermission({
      permissionName: 'view.data',
    })
    return (
      <div style={this.styles.container}>
        <RedirectWithoutPermission
          multiPermissions={[
            { permissionName: 'edit.users' },
            { permissionName: 'edit.invites' },
            { permissionName: 'edit.roleassignments' },
          ]}
        />
        <div style={this.styles.doubleGuy}>
          <SectionHeader>
            <FormattedMessage {...messages.UserTitle} />
          </SectionHeader>
          <div style={this.styles.keepRight}>
            <WithPermission permissionName="view.audit">
              <TextLink
                style={{ margin: '1em' }}
                color="primary"
                onClick={() => {
                  this.props.push({
                    pathname: '/App/UserManagement/UserActivity',
                  })
                }}
              >
                <DynamicFormattedMessage {...messages.userActivityLink} />
              </TextLink>
            </WithPermission>
            <WithPermission
              multiPermissions={[
                {
                  permissionName: 'edit.users',
                  resourceId: ({ org }) => `/org/${org}/`,
                },
                {
                  permissionName: 'edit.invites',
                  resourceId: ({ org }) => `/org/${org}/`,
                },
              ]}
            >
              <Button
                style={{ marginBottom: '1em' }}
                color="primary"
                onClick={this.openAddEditModal}
              >
                <FormattedMessage {...messages.InviteUser} />
              </Button>
            </WithPermission>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateRows: '3fr 2.25fr',
            gridTemplateAreas: '"user" "pending"',
          }}
        >
          <div style={this.styles.userTable}>
            <BorderedCard style={{ height: '100%' }}>
              {this.renderUserTable()}
            </BorderedCard>
          </div>
          <div style={this.styles.pendingTable}>
            <div style={this.styles.title}>
              <FormattedMessage {...messages.PendingTitle} />
            </div>
            <BorderedCard style={{ height: '100%' }}>
              {this.renderPendingUsers()}
            </BorderedCard>
          </div>
        </div>
        {this.state.addEditModalIsOpen && (
          <AddEditInviteModal
            error={this.props.userManagementError}
            toggle={this.closeModal}
            isOpen={this.state.addEditModalIsOpen}
            user={this.state.pendingUser}
            inviteUser={this.props.inviteUser}
            invitingUser={this.props.invitingUser}
            updateUserInvite={this.props.updateUserInvite}
            updatingUserInvite={
              this.state.pendingUser
                ? this.props.updatingUserInvite.get(
                    this.state.pendingUser.get('srn')
                  )
                : null
            }
            updatePendingRoleAssignments={
              this.props.updatePendingRoleAssignments
            }
            canViewData={canViewData}
            unavailableEmails={this.getAllEmails()}
          />
        )}
      </div>
    )
  }
}

UserManagement.propTypes = {
  users: ImmutablePropTypes.map,
  sonraiScopes: ImmutablePropTypes.list,
  fetchPendingUsers: PropTypes.func,
  pendingUsers: ImmutablePropTypes.list,
  cancellingUserInvites: ImmutablePropTypes.map,
  fetchingPendingUsers: PropTypes.bool,
  usersLoading: PropTypes.bool,
  push: PropTypes.func,
  inviteUser: PropTypes.func,
  invitingUser: PropTypes.bool,
  updateUserInvite: PropTypes.func,
  updatingUserInvite: ImmutablePropTypes.map,
  resendUserInvite: PropTypes.func,
  cancelUserInvite: PropTypes.func,
  resendingUserInvite: ImmutablePropTypes.map,
  userHasPermission: PropTypes.func.isRequired,
  updatePendingRoleAssignments: PropTypes.func.isRequired,
  userManagementError: PropTypes.string,
  clearUserManagementError: PropTypes.func,
  updatingUserRoles: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  users: selectSonraiUsers,
  usersLoading: selectSonraiUsersLoading,
  pendingUsers: selectPendingUsers,
  fetchingPendingUsers: selectFetchingPendingUsers,
  sonraiScopes: selectSonraiScopes,
  cancellingUserInvites: selectCancellingUserInvite,
  invitingUser: selectInvitingUser,
  updatingUserInvite: selectUpdatingUserInvite,
  resendingUserInvite: selectResendingUserInvite,
  userManagementError: selectUserManagementError,
  updatingUserRoles: selectUpdatingUserRoles,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchPendingUsers,
      inviteUser,
      updateUserInvite,
      cancelUserInvite,
      resendUserInvite,
      updatePendingRoleAssignments,
      clearUserManagementError,
      push,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withSaga = injectSaga({
  key: 'userManagementData',
  saga: userManagementSaga,
})
const withReducer = injectReducer({
  key: 'userManagementData',
  reducer: userManagementReducer,
})

export default compose(
  withReducer,
  withSaga,
  withConnect,
  permissionChecker
)(UserManagement)
